<script>
export default {
  name: 'TooltipCard',
  data () {
    return {
      understood: false
    }
  },
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    clicked: { type: Boolean, default: false },
    btnName: { type: String, default: '' }
  },
  methods: {
    setUnderstood () {
      this.$store.commit('updateUnderstood', true)
      this.understood = true
    }
  },
  created () {
    if (this.$store.getters.getUserUnderstood) this.understood = true
  },
  watch: {
    clicked: {
      handler () {
        this.setUnderstood()
      }
    }
  }
}
</script>
<template>
  <div class="tooltip-container"  v-if="!isMobile && !understood && (!!this.$route.params.companyDomain && this.$route.fullPath.search('/c/') > -1)">
    <div class="tooltip-container-triangule">

      <div class="triangule"></div>

    </div>
    <v-card

      class="tooltip-card rounded-lg" max-width="300">

      <v-card-text class="tooltip-card-text">
        <span><b>{{ title }}</b></span><br />
        <span>{{ subtitle }}</span>
      </v-card-text>

      <div class="tooltip-button-action">
        <v-btn @click="setUnderstood" class="btn bold transform-unset" color="#1200D3" text>
          {{ btnName }}
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<style lang="scss">
.tooltip-container {

  .tooltip-container-triangule {

    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: right;

    .triangule {
      z-index: 121;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgb(255, 255, 255);
    }

  }

  .tooltip-card {
    z-index: 120;
    text-align: left;
    margin-top: 10px;

    .tooltip-card-text {
      margin-top: -10px;
      margin-bottom: -20px;

      span {
        color: #000000;
      }
    }

    .tooltip-button-action {
      text-align: right;
    }
  }
}

</style>
