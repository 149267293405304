<script>
import HeaderAvatarMenu from '@/components/general/HeaderAvatarMenu'
import ModalSelectAction from '@/components/general/ModalSelectAction'
import UserAvatarInitials from '@/components/general/UserAvatarInitials'
import TooltipCard from './TooltipCard.vue'
export default {
  components: { HeaderAvatarMenu, ModalSelectAction, UserAvatarInitials, TooltipCard },
  name: 'HeaderMenu',
  data () {
    return {
      selectedTab: 0,
      mobileMenu: false,
      activeProfile: false,
      clicked: false
    }
  },
  computed: {
    customMenuColors () {
      return this.selectedWorkspace.customStyle && this.selectedWorkspace.customStyle.menuColors
    },
    user () {
      return this.$store.getters.getUser || {}
    },
    currentRouteName () {
      return this.$route.name
    },
    userProfile () {
      return this.$store.getters.getIndividualWorkspace
    },
    avatarUrl () {
      return this.changeThumbToSize(this.userProfile.avatar, 'avatar')
    },
    isAdminCenter () {
      return this.currentRouteName.includes('business.admin.center')
    },
    actions () {
      const actions = ['training.and.development', 'recruitment.and.selection']

      if (this.selectedWorkspace.type === 'business' && this.selectedWorkspace.userRole === 'admin') actions.push('admin.center')

      return actions
    }
  },
  watch: {
    selectedWorkspace () {
      this.updatedSelectedTab()
    },
    currentRouteName () {
      this.updatedSelectedTab()
    }
  },
  methods: {
    allowedOnWorkspaceType (allowedTypes) {
      return allowedTypes && allowedTypes.includes(this.selectedWorkspace.type)
    },
    updatedSelectedTab () {
      this.activeProfile = this.currentRouteName.includes('settings') ?? false

      if (this.currentRouteName.includes('settings')) {
        this.selectedTab = 0
      } else if (this.currentRouteName.includes('discovery')) {
        this.selectedTab = 1
      } else if (this.currentRouteName.includes('details') && this.currentRouteName !== 'individual.home.user.content.details' && this.currentRouteName !== 'business.home.user.lists.details' && !this.currentRouteName.includes('contents')) {
        this.selectedTab = 1
      } else if (this.currentRouteName.includes('contents')) {
        this.selectedTab = 2
      } else if (this.selectedWorkspace.type === 'business' && this.currentRouteName.includes('internal.positions')) {
        this.selectedTab = 3
      } else if (this.selectedWorkspace.type !== 'business' && this.currentRouteName.includes('candidature')) {
        this.selectedTab = 3
      } else if (this.selectedWorkspace.type === 'business' && this.currentRouteName.includes('candidature.closed')) {
        this.selectedTab = 3
      } else if (this.selectedWorkspace.type === 'business' && this.currentRouteName.includes('career')) {
        this.selectedTab = 4
      } else {
        this.selectedTab = 0
      }
    },
    redirectToHome () {
      if (this.$route.name !== this.selectedWorkspace.type + '.home.user') this.$router.push({ name: this.selectedWorkspace.type + '.home' })
      setTimeout(() => {
        this.updatedSelectedTab()
      }, 50)
    },
    getLogo () {
      if (this.selectedWorkspace.type === 'aggregator' && this.selectedWorkspace.customStyle) {
        return this.selectedWorkspace.customStyle.logo
      }
      return {
        src: `/assets/images/${this.selectedWorkspace.type === 'individual' ? 'primary-logo-dark' : 'primary-logo'}.svg`,
        height: '32px',
        width: '123px'
      }
    },
    getBgColor () {
      if (this.selectedWorkspace.type === 'aggregator' && this.selectedWorkspace.customStyle) {
        return this.customMenuColors.primaryColor
      }
      return this.selectedWorkspace.type === 'individual' ? '#1200D3' : '#ffffff'
    },
    getCustomAccentColor () {
      if (this.selectedWorkspace.type === 'aggregator' && this.selectedWorkspace.customStyle) {
        return this.selectedWorkspace.customStyle.secondaryColor
      }
      return this.selectedWorkspace.type === 'individual' ? '#F7BF34' : '#1200D3'
    },
    centralActionHandler (action) {
      switch (action) {
        case 'myAccount':
          this.$router.push({
            name: 'settings.profile.form',
            params: {
              fullPath: this.$route.fullPath,
              workspaceAvatar: this.selectedWorkspace.avatar,
              workspaceType: this.selectedWorkspace.type
            }
          })
          break
        case 'training.and.development':
          this.redirectToHome()
          break
        case 'admin.center':
          this.$router.push({ name: `${this.selectedWorkspace.type}.admin.center.index` })
          break
        case 'recruitment.and.selection':
          window.location.href = `/${this.selectedWorkspace.type === 'aggregator' ? 'p' : 'c'}/${this.selectedWorkspace.subdomain}/hire/`
          break
        case 'logout':
          this.$store.dispatch('attemptDeleteAuthToken').then(() => {
            this.$store.commit('updateUser', { clear: true })
            this.$store.commit('setWorkspaceInvitesRemindLater', false)
            this.clearStorageAndLogout()
          })
          break
        default:
          return null
      }
    },
    handleClickMenuModules () {
      this.clicked = true
    }
  },
  created () {
    this.updatedSelectedTab()
  }
}
</script>
<template>
  <v-card class="overflow-hidden menu-header">
    <v-app-bar class="header-menu-container" :color="getBgColor()" fixed height="64px" flat elevation="4">
      <div class="menu-company--name">
        <v-img class="menu-company--image__content" :src="getLogo().src" :height="getLogo().height"
          :width="getLogo().width"
          @click="isAdminCenter ? $router.push({ name: 'business.admin.center.index' }) : redirectToHome()" />
      </div>
      <div v-if="!isMobile" class="menu-header--options">
        <v-tabs :key="selectedWorkspace.id" height="64"
          :color="(customMenuColors && customMenuColors.secondaryColor) || getCustomAccentColor()" v-model="selectedTab"
          slider-size="4" class="pa-0 header-menu-tabs" :class="[selectedWorkspace.type, { 'dark-text': isDarkTextMenu }]"
          v-if="!isUserDeleted">
          <v-tabs-slider />
          <template v-if="!isAdminCenter && !activeProfile">
            <v-tab class="btn transform-unset" @click="redirectToHome">{{ $t('main.header:home') }}</v-tab>
            <v-tab class="btn transform-unset"
              @click="selectedWorkspace.type === 'individual' || selectedWorkspace.type === 'aggregator' ? $route.name !== selectedWorkspace.type + '.discovery.index.search' ? $router.push({ name: selectedWorkspace.type + '.discovery.index' }) : '' : $route.name !== selectedWorkspace.type + '.discovery.index' ? $router.push({ name: selectedWorkspace.type + '.discovery.index' }) : ''">{{
                $t('main.header:discovery')
              }}</v-tab>
            <v-tab class="btn transform-unset"
              @click="selectedWorkspace.type === 'individual' ? $route.name !== selectedWorkspace.type + '.home.user.contents.published' ? $router.push({ name: selectedWorkspace.type + '.home.user.contents.published' }) : '' : selectedWorkspace.type === 'aggregator' ? $route.name !== selectedWorkspace.type + '.home.user.contents' ? $router.push({ name: selectedWorkspace.type + '.home.user.contents' }) : '' : $route.name !== selectedWorkspace.type + '.contents.published' ? $router.push({ name: selectedWorkspace.type + '.contents.published' }) : ''">{{
                $t('main.header:contents')
              }}</v-tab>
            <v-tab v-if="allowedOnWorkspaceType(['business'])" class="btn transform-unset"
              @click="$route.name !== selectedWorkspace.type + '.internal.positions.under.analysis' ? $router.push({ name: selectedWorkspace.type + '.internal.positions' }) : ''">{{
                $t('main.header:internal.positions')
              }}</v-tab>
            <v-tab v-if="allowedOnWorkspaceType(['individual', 'aggregator'])" class="btn transform-unset"
              @click="$route.name !== selectedWorkspace.type + '.candidature.under.analysis' ? $router.push({ name: selectedWorkspace.type + '.candidature.index' }) : ''">{{
                $t('main.header:candidacy')
              }}</v-tab>
          </template>
          <v-tab v-if="isAdminCenter" class="btn transform-unset"
            @click="$router.push({ name: selectedWorkspace.type + '.admin.center.index' })">{{
              $t('home.header.options:admin.center')
            }}</v-tab>
          <v-tab v-if="activeProfile" class="btn transform-unset"
            @click="$router.push({ name: 'settings.profile.form' })">{{
              $t('home.header.options:profile')
            }}</v-tab>
        </v-tabs>
      </div>
      <div class="header-menu--actions">
        <div class="header-menu-mobile--wrapper" v-if="isMobile">
          <div class="icon-wrapper mobile-menu-icon--wrapper"
            :style="{ 'border-color': isAggregator ? ['empregosagro', 'empregatransporte'].includes(selectedWorkspace.subdomain) ? selectedWorkspace.customStyle.secondaryColor : selectedWorkspace.customStyle.primaryColor : isBusiness ? '#1200D3' : '#fff' }"
            @click="mobileMenu = !mobileMenu">
            <v-icon
              :color="isAggregator ? ['empregosagro', 'empregatransporte'].includes(selectedWorkspace.subdomain) ? selectedWorkspace.customStyle.secondaryColor : selectedWorkspace.customStyle.primaryColor : isBusiness ? '#1200D3' : '#fff'">mdi-menu</v-icon>
          </div>
        </div>
        <modal-select-action icon="mdi-plus" class="modal-select-action-w rappermr-4"
          v-if="!isUserDeleted && selectedWorkspace.type === 'individual' && !isMobile" />
        <div class="tooltip-container">
          <div class="tooltip-container-select">

            <v-menu v-if="!isMobile && selectedWorkspace.type === 'business'" bottom left transition="none"
              min-width="200" z-index="111" :key="user.name + '0' || 0" :offset-y="true">
              <template v-slot:activator="{ on, attrs }">
                <div class="central--wrapper mr-4" v-on="on" v-bind="attrs" @click="handleClickMenuModules()">
                  <v-icon color="#454545">mdi-view-module</v-icon>
                  <span style="padding-bottom: 1px">{{
                    $t(`home.header.options.initials:${$route.name.indexOf('business.admin.center')
                      === 0 ? 'admin.center' : 'training.and.development'
                      }`) }}</span>
                </div>
              </template>
              <div class="central-menu-options--wrapper">
                <v-list-item v-for="(action, index) in actions" :key="index" @click="centralActionHandler(action)"
                  :class="{ 'active': (index === 0 && $route.name.indexOf('business.admin.center') != 0) || (index == 2 && $route.name.indexOf('business.admin.center') === 0) }">
                  <span class="v-list-item-text">{{ $t(`home.header.options:${action}`) }}</span>
                  <v-icon
                    v-if="(index === 0 && $route.name.indexOf('business.admin.center') != 0) || (index == 2 && $route.name.indexOf('business.admin.center') === 0)">mdi-check</v-icon>
                </v-list-item>
              </div>
            </v-menu>
          </div>
          <TooltipCard class="tooltip-container-tooltip" :title="$t('module.change.tip:title')"
            :subtitle="$t('module.change.tip:subtitle')" :clicked="clicked"
            :btnName="$t('mudulo.change.tip:confirm.btn')" />
        </div>
        <v-menu v-if="!isMobile" bottom left transition="none" min-width="200" z-index="111" :key="user.name + '1' || 0"
          :offset-y="true">
          <template v-slot:activator="{ on, attrs }">
            <div class="avatar--wrapper" v-on="on" v-bind="attrs">
              <template v-if="selectedWorkspace.type === 'business'">
                <span class="company-avatar-name--wrapper mr-1">{{ selectedWorkspace.name }}</span>
              </template>
              <v-avatar color="indigo" size="36">
                <img v-if="!!avatarUrl && !(avatarUrl.includes('/empty'))" :src="avatarUrl || defaultAvatarUrl('profile')"
                  alt="Avatar">
                <user-avatar-initials v-else :user="workspace" :size="36" />
              </v-avatar>
            </div>
          </template>
          <header-avatar-menu :centralActionHandler="centralActionHandler" />
        </v-menu>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="mobileMenu" fixed temporary width="100%">
      <header-avatar-menu @close="mobileMenu = false" :isAdminCenter="isAdminCenter" :activeProfile="activeProfile"
        :redirectToHome="redirectToHome" :allowedOnWorkspaceType="allowedOnWorkspaceType"
        :centralActionHandler="centralActionHandler" />
    </v-navigation-drawer>
</v-card>
</template>
<style lang="scss">
.menu-header {
  z-index: 110;

  .v-toolbar__content {
    padding: 0 16px;
    justify-content: flex-start;

  }

  .col-2,
  .col-7,
  .col-3,
  .col-8 {
    padding: 0;

  }

  .theme--light.v-tabs>.v-tabs-bar {
    background-color: transparent;
  }

  .menu-header--options {
    flex: 1;
    align-self: flex-end;

    .font-weight-bold {
      padding: 10px 16px 20px;
    }
  }

  .header-menu-container {
    padding: 0 40px;
  }

  .header-menu--actions {
    max-width: 1100px;
    height: 44px;
    display: flex;
    justify-content: flex-end;
    flex: 1;
    min-width: 16.666%;
    font-weight: 600;

    .v-btn.theme--dark.v-btn--has-bg {
      background-color: $secondary-medium;
      margin-right: 20px;

      .v-icon {
        color: $neutral-black;
      }
    }

    .tooltip-container {
      display: inline-block;

      .tooltip-container-select {
        display: flex;
        justify-content: right;
        align-items: center;

        .central--wrapper {
          background-color: #EDEDED;
          border: 1px solid #DBD5CE;
          width: 55px;
          height: 44px;
          padding: 4px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;

        }
      }

      .tooltip-container-tooltip {
        margin-right: 16px;
      }

    }

    .avatar--wrapper {
      background-color: #EDEDED;
      border: 1px solid #DBD5CE;
      padding: 4px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      .company-avatar-name--wrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px;
        background-color: $primary-medium;
        color: white;
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 24px;
      }
    }

    .mobile-menu-icon--wrapper {
      border: 1px solid #1200D3;
      border-radius: 8px;
      height: 44px;
      width: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .menu-company--name {
    margin-right: 32px;

    .menu-company--image__content {
      cursor: pointer;
    }
  }
}

.menu-header.overflow-hidden.v-card {
  border-radius: 0;
}

.central-menu-options--wrapper {
  z-index: 112;
  background-color: white;
  padding: 8px 0;

  .v-list-item {
    padding-right: 56px;
  }

  .active {
    background: rgba(69, 69, 69, 0.1);
    padding-right: 0;
  }

  .v-icon {
    margin-left: 16px;
    margin-right: 16px;
  }

  .v-list-item-text {
    flex: 1;
    text-align: left;
  }
}

.header-menu-tabs {
  font-weight: bold;
  color: $primary-medium;
  caret-color: $primary-medium;

  &.individual {
    .v-tabs-bar {
      background-color: transparent;

      .v-tab:not(.v-tab--active),
      .v-tab {
        color: #FFFFFF;
      }

      .v-tab--active {
        color: $secondary-medium;
      }

      .v-tabs-slider {
        background-color: $secondary-medium;
      }
    }
  }

  &.aggregator {
    &.dark-text {
      .v-tabs-bar {
        .v-tab:not(.v-tab--active) {
          color: #000000;
        }
      }
    }

    .v-tabs-bar {
      .v-tab:not(.v-tab--active) {
        color: $neutral-white;
      }
    }
  }

  &.business {
    .v-tab {
      letter-spacing: normal;
      min-width: initial;
    }
  }
}

@media only screen and (max-width: 1148px) {
  .menu-header .header-menu-container {
    padding: 0 20px;

    .v-toolbar__content {
      padding: 0;

      .mobile-avatar--wrapper {
        .v-avatar .v-image {
          height: 44px;
          width: 44px;
        }
      }
    }

    .menu-company--name {
      margin-right: 12px;

      .menu-company--image__content {
        margin: 0 auto;
      }
    }

    .header-menu--actions {
      .modal-selection-action {
        position: unset;
      }
    }
  }
}

@media only screen and (max-width: 900px) {

  .header-menu-tabs {
    width: 260px;
  }
}
</style>
